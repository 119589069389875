<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small" @submit.native.prevent>
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" @keyup.enter.native="query"/>
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="序号" width="80" align="center">
				  <template slot-scope="scope">
					{{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
				  </template>
				</el-table-column>
				<el-table-column label="标题" min-width="150" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.title }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="类型" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.name }}
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="50" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.is_pub === 2" size="mini" type="danger">停用</el-tag>
					<el-tag v-if="scope.row.is_pub === 1" size="mini" type="success">正常</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="130">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="编辑" placement="top">
					  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'article',
				queryForm: {
					page: 1,
					limit: 10,
					type_id:2,
					keyword: '',
				},
				types:[],
			}
		},
		mounted() {
			this.getArticleTypes()
		},
		methods:{
			getArticleTypes(){
				this.axios.get(`/manage/article_type/getTree`, {
				  params: this.queryForm
				}).then(res=>{
				  if(res.status){
					this.types = res.data
				  }
				})
			},
			handleAdd(){
				this.$router.push({
				  path:"/xhdt_edit",
				  query: {}
				})
            },
			handleEdit(row) {
				this.$router.push({
				  path:"/xhdt_edit",
				  query: {
					  article:JSON.parse(JSON.stringify(row))
				  }
				})
			},
	    },
		filters: {
		    tree(item) {
		        if (item.level == 0) {
		          return item.name
		        }
		        let str = ''
		        for (let i = 0; i < item.level; i++) {
		          str += i == 0 ? '|--' : '--'
		        }
		        return str + ' ' +item.name;
		    }
		},
	}
</script>

<style>
</style>